<template>
	<div>
		<img :src="proxy.$axios.imgUrl + topImg" class="topImg-m" />
		<div class="main">
			<div class="detail-title">{{info.title}}</div>
			<div class="detail-timer">{{info.date_en}}</div>
			<img :src="proxy.$axios.imgUrl + info.images" class="detail-image" />
			<div class="detail-content">
				<span v-html="info.content"></span>
			</div>
		</div>
		<div class="divier"></div>
		<div class="newList main">
			<div class="Company-title">
				News recommendations
				<div class="Company-title-border flex-center">
					<div class="red"></div>
					<div class="blue"></div>
				</div>
			</div>
			<view class="list-item alcenter" v-for="(item,index) in list" :key="index" @click="jump(item.id)">
				<img :src="proxy.$axios.imgUrl + item.images" class="left" />
				<div class="content">
					<view class="title">{{item.title}}</view>
					<view class="subhead">{{item.synopsis}}</view>
					<view class="timer flex-layout">
						<view>{{item.date}},{{item.year}}</view>
						<img src="../../assets/image/ic_morejt.png" class="more" />
					</view>
				</div>
			</view>
		</div>
	</div>
</template>

<script setup>
	import {
		onMounted,
		getCurrentInstance,
		ref
	} from 'vue';
	import {
		useRoute,
		useRouter
	} from 'vue-router'

	const route = useRoute()
	const router = useRouter()

	import EventBus from "../../assets/common/event-bus.js"

	const {
		proxy
	} = getCurrentInstance()

	onMounted(() => {
		EventBus.emit("mgheadr", 4);
		getInfo()
		getList()
		getTopimg()
	})

	const info = ref({})

	async function getInfo() {
		let res = await proxy.$axios.get('/api/index/getNewsDetails', {
			id: route.query.id
		})
		info.value = res.data
	}


	const list = ref([])

	async function getList() {
		let res = await proxy.$axios.get('/api/index/getNewsData', {
			page: 1
		})
		if (Array.isArray(res.data) && res.data.length) {
			list.value = res.data
		}
	}

	function jump(id) {
		router.push({
			path: '/newsdetail-m',
			query: {
				id
			}
		})
	}


	const topImg = ref('')

	async function getTopimg() {
		let res = await proxy.$axios.get('/api/index/getTopImg', {
			type: 'news'
		})
		topImg.value = res.data.top_img
	}
</script>

<style scoped>
	.topImg-m {
		width: 100%;
		height: auto;
		display: block;
	}

	.detail-title {
		font-weight: 500;
		font-size: 0.3rem;
		color: #000000;
		line-height: 0.38rem;
		margin-top: 0.4rem;
	}

	.detail-timer {
		font-weight: 400;
		font-size: 0.22rem;
		color: #999999;
		line-height: 0.22rem;
		margin-top: 0.24rem;
	}

	.detail-image {
		width: 100%;
		display: block;
		margin-top: 0.45rem;
	}

	.detail-content {
		font-weight: 400;
		font-size: 0.26rem;
		color: #333333;
		line-height: 0.4rem;
		margin-top: 0.3rem;
		padding-bottom: 0.42rem;
	}

	.divier {
		width: 100%;
		height: 0.24rem;
		background: #F6F6F6;
	}

	.topImg-m {
		width: 100%;
		height: auto;
		display: block;
	}

	.newList {
		margin-top: 0.3rem;
	}

	.list-item {
		padding: 0.32rem 0;
		border-bottom: 1px solid #EEEEEE;
	}

	.list-item .left {
		width: 2.44rem;
		height: 1.62rem;
	}

	.list-item .content {
		flex: 1;
		margin-left: 0.25rem;
	}

	.list-item .content .title {
		font-weight: 500;
		font-size: 0.26rem;
		color: #000000;
		line-height: 0.326rem;
		word-break: break-all;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
	}

	.list-item .content .subhead {
		margin-top: 0.16rem;
		font-weight: 400;
		font-size: 0.22rem;
		color: #666666;
		line-height: 0.32rem;
		word-break: break-all;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.list-item .content .timer {
		font-size: 0.22rem;
		color: #999999;
		line-height: 0.22rem;
		margin-top: 0.20rem;
	}

	.list-item .content .timer>img {
		width: 0.48rem;
		height: 0.24rem;
		display: block;
	}

	.Company-title {
		font-weight: 500;
		font-size: 0.34rem;
		color: #000000;
		padding-bottom: 0.09rem;
		position: relative;
		text-align: left;
	}

	.Company-title-border {
		height: 0.02rem;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 0.64rem;
	}

	.Company-title-border .red {
		height: 100%;
		width: 0.12rem;
		background-color: #E83328;
	}

	.Company-title-border .blue {
		height: 100%;
		width: 0.48rem;
		background-color: #008AD2;
		margin-left: 0.04rem;
	}
</style>